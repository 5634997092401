<template>
    <div class='allTime'>
        <!-- 非时间段选择器时展示前后切换按钮 -->
        <div class="flexOne" v-show="activeTab!=='daterange'">
            <el-tooltip class="item" effect="dark" :content="$t(tipContentPre)" placement="top-start">
                <span @click="preWeek" class="el-icon-arrow-left checkIcon" role="1"></span>
            </el-tooltip>
        </div>
        <div class="flexOne">
            <el-popover placement="bottom-start" popper-class="projrctAllTinmeOut" v-model="visible" :width="popWidth" trigger="click" @show="showPopover" style="overflow:hidden;">
                <el-tabs v-model="activeTab" @tab-click="handleTabClick" class="tabContainer">
                    <!-- 天 -->
                    <el-tab-pane :label="$t('label.chatter.day')" name="date"></el-tab-pane>
                    <!-- 周 -->
                    <el-tab-pane :label="$t('label.week')" name="week"></el-tab-pane>
                    <!-- 月 -->
                    <el-tab-pane :label="$t('label.login.access.month')" name="month"></el-tab-pane>
                    <!-- <el-tab-pane label="范围" name="daterange"></el-tab-pane> -->
                    <el-date-picker popper-class="projrctAllTinme" ref="datePicker" v-show="activeTab === 'date'" format="yyyy-MM-dd" v-model="time" @blur="pickerBlur($event)"  type="date" placeholder="选择日">
                    </el-date-picker>
                    <el-date-picker popper-class="projrctAllTinme" ref="weekPicker" v-show="activeTab === 'week'" v-model="time" type="week" format="yyyy 第 WW 周" :editable="false"  @blur="pickerBlur($event)" 
                         placeholder="选择周"></el-date-picker>
                    <el-date-picker popper-class="projrctAllTinme" ref="monthPicker" v-show="activeTab === 'month'" v-model="time" type="month" format="yyyy-MM" :editable="false"  @blur="pickerBlur($event)"  
                        placeholder="选择月"></el-date-picker>
                    <el-date-picker popper-class="projrctAllTinme" ref="daterangePicker" v-show="activeTab === 'daterange'" v-model="daterangeVal" type="daterange"  @blur="pickerBlur($event)"  
                        range-separator='到' start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-tabs>
                <div slot="reference" class="downIcon">
                    <span style="cursor:pointer;" role="1">
                        <i class="el-icon-date dateIcon" role="1"></i>
                        <span role="1">{{showDateTime}}</span>
                    </span>
                </div>
            </el-popover>
        </div>
        <div class="flexOne" v-show="activeTab!=='daterange'">
            <el-tooltip class="item" effect="dark" :content="$t(tipContentNex)" placement="top-start">
                <span @click="nextWeek" class="el-icon-arrow-right checkIcon" role="1"></span>
            </el-tooltip>
        </div>
    </div>
</template>

<script>

export default {
    name: "AllTime",
    components: {},
    props: {
        activeTabNow:{ //默认选中的tab页
            type:String,
            default:"date"
        },
        curCheckTime:{
            type:Array,
        }
    },
    data () {
        return {
            activeTab:this.activeTabNow,
            visible: false,
            time: null,
            daterangeVal: null,
            popWidth: 325,
            checkDateTime: new Date(),
            showDateTime: new Date(),
            tipContentPre: "label.appointmentpage.wizard.subtitle1-2",//"上一周",
            tipContentNex: "label.appointmentpage.wizard.subtitle1-1",//"下一周",
            changePicker:false,
            dayDateOption: {
                firstDayOfWeek: 1,
                disabledDate (time) {
                    return time.getTime() > Date.now();
                }
            },
            weekDateOption: {
                firstDayOfWeek: 1,
                disabledDate (time) {
                    const week = new Date().getDay();
                    return time.getTime() > Date.now() - 8.64e7 * week;
                }
            },
            monthDateOption: {
                disabledDate (time) {
                    return time.getTime() > Date.now();
                }
            },
        };
    },
    computed: {},
    watch: {
        activeTab(){
            this.$cookies.set("activeTab",this.activeTab, { sameSite: 'Strict' })
        },
        showDateTime(){
            this.$cookies.set("showDateTime",this.showDateTime, { sameSite: 'Strict' })
        },
    },
    methods: {
        // preWeek nextWeek方法：
        // 1、点击时给time赋值，确保点点击前后按钮时日期插件选中的时间time和显示时间showDateTime一致
        // 2、emit的checkTimePicker方法，给父组件传值，可以获取当前时间组件的类型及选中的值
        // 3、checkDateTime为当前点击的日期前/后的值

        // 前几天
        preWeek () {
            if (this.activeTab === 'date') {
                this.time = this.$moment(this.checkDateTime).subtract(1, 'days');
                this.showDateTime = this.$moment(this.checkDateTime).subtract(1, 'days').format("YYYY-MM-DD");
                this.$emit('checkTimePicker', "date", this.showDateTime)
                this.checkDateTime = this.$moment(this.checkDateTime).subtract(1, 'days');
            } else if (this.activeTab === 'week') {
                this.time = this.$moment(this.checkDateTime).subtract(1, 'weeks');
                let start = this.$moment(this.checkDateTime).subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD");
                let end = this.$moment(this.checkDateTime).subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
                this.showDateTime = start +"  "+ this.$i18n.t("component.chatter.label.to")+"  " + end;
                this.$emit('checkTimePicker', "week", [start,end]);
                this.checkDateTime = this.$moment(this.checkDateTime).subtract(1, 'weeks');
            } else if (this.activeTab === 'month') {
                this.time = this.$moment(this.checkDateTime).subtract(1, 'months');
                let start = this.$moment(this.checkDateTime).subtract(1, 'months').startOf('months').format("YYYY-MM-DD");
                let end  = this.$moment(this.checkDateTime).subtract(1, 'months').endOf('months').format("YYYY-MM-DD");
                this.$emit('checkTimePicker', "month", [start,end]);
                this.showDateTime =  start +"  "+ this.$i18n.t("component.chatter.label.to")+"   " + end;
                this.checkDateTime = this.$moment(this.checkDateTime).subtract(1, 'months');
            }
        },
        // 后几天
        nextWeek () {
            if (this.activeTab === 'date') {
                this.time = this.$moment(this.checkDateTime).subtract(-1, 'days');
                this.showDateTime = this.$moment(this.checkDateTime).subtract(-1, 'days').format("YYYY-MM-DD");
                this.$emit('checkTimePicker', "date", this.showDateTime);
                this.checkDateTime = this.$moment(this.checkDateTime).subtract(-1, 'days');
            } else if (this.activeTab === 'week') {
                this.time = this.$moment(this.checkDateTime).subtract(-1, 'weeks');
                let start = this.$moment(this.checkDateTime).subtract(-1, 'weeks').startOf('week').format("YYYY-MM-DD");
                let end  = this.$moment(this.checkDateTime).subtract(-1, 'weeks').endOf('week').format("YYYY-MM-DD");
                this.$emit('checkTimePicker', "week", [start,end]);
                this.showDateTime = start+" " + this.$i18n.t("component.chatter.label.to") +" " +end;
                this.checkDateTime = this.$moment(this.checkDateTime).subtract(-1, 'weeks');
            } else if (this.activeTab === 'month') {
                this.time = this.$moment(this.checkDateTime).subtract(-1, 'months');
                let start = this.$moment(this.checkDateTime).subtract(-1, 'months').startOf('months').format("YYYY-MM-DD");
                let end = this.$moment(this.checkDateTime).subtract(-1, 'months').endOf('months').format("YYYY-MM-DD");
                this.$emit('checkTimePicker', "month", [start,end]);
                this.showDateTime = start +" "+ this.$i18n.t("component.chatter.label.to")+" " + end;
                this.checkDateTime = this.$moment(this.checkDateTime).subtract(-1, 'months').format("YYYY-MM-DD")
            }
        },
        // 显示popover
        showPopover () {
            this.handleTabClick();
        },
        // tab切换
        handleTabClick () {
            this.visible = true;
            this.$nextTick(() => {
                if (this.activeTab === 'date') {
                    this.tipContentPre =this.$i18n.t("label.many.dayline.lastday");//前一天
                    this.tipContentNex =  this.$i18n.t("label.many.dayline.nextday");//后一天
                    this.popWidth = 325;
                    this.$refs.datePicker.focus();
                } else if (this.activeTab === 'week') {
                    this.tipContentPre =  this.$i18n.t("label.appointmentpage.wizard.subtitle1-2");//"上一周";
                    this.tipContentNex =  this.$i18n.t("label.appointmentpage.wizard.subtitle1-1");//"下一周";
                    this.popWidth = 325;
                    this.$refs.weekPicker.focus();
                } else if (this.activeTab === 'month') {
                    this.tipContentPre =  this.$i18n.t("fliter.lastmonth"),//"上个月";
                    this.tipContentNex =  this.$i18n.t("fliter.nextmonth"),//"下个月";
                    this.popWidth = 325;
                    this.$refs.monthPicker.focus();
                } else if (this.activeTab === 'daterange') {
                    this.popWidth = 680;
                    this.$refs.daterangePicker.focus();
                } 
            })
        },
        //日期插件失去焦点
        pickerBlur(picker){
            this.visible = false;
            let flag  = event.target.getAttribute("role");
            if(!flag){
                if (this.activeTab === 'date') {
                    this.showDateTime='';
                    this.checkDateTime = picker.value;
                    this.showDateTime = this.$moment(picker.value).format("YYYY-MM-DD")
                    this.$emit('checkTimePicker', "date", this.showDateTime)
                } else if (this.activeTab === 'week') {
                    this.checkDateTime = picker.value;
                    let start = this.$moment(picker.value).startOf('week').format("YYYY-MM-DD");
                    let end = this.$moment(picker.value).endOf('week').format("YYYY-MM-DD");
                    this.showDateTime = start + this.$i18n.t("component.chatter.label.to") + end;
                    this.$emit('checkTimePicker', "week", [start,end]);
                } else if (this.activeTab === 'month') {
                    this.checkDateTime = picker.value;
                    let start = this.$moment(picker.value).startOf('months').format("YYYY-MM-DD");
                    let end = this.$moment(picker.value).endOf('months').format("YYYY-MM-DD");
                    this.showDateTime = start + this.$i18n.t("component.chatter.label.to") + end;
                    this.$emit('checkTimePicker', "month", [start,end]);
                } else if (this.activeTab === 'daterange') {
                    let start = this.$moment(picker.value[0]).format("YYYY-MM-DD");
                    let end = this.$moment(picker.value[1]).format("YYYY-MM-DD");
                    this.showDateTime = start + this.$i18n.t("component.chatter.label.to") + end;
                    this.$emit('checkTimePicker', "daterange", [start,end]);
                }
            }
            
        },
    },
    created () {
        if(this.curCheckTime[0]){
            if (this.activeTab === 'date') {
                this.showDateTime = this.$moment(this.curCheckTime[0]).format("YYYY-MM-DD");
                this.$emit('checkTimePicker', "date", this.showDateTime,)
            } else if (this.activeTab === 'week') {
                this.showDateTime = this.curCheckTime[0] + this.$i18n.t("component.chatter.label.to") + this.curCheckTime[1];
                this.$emit('checkTimePicker', "week", this.curCheckTime,);
            } else if (this.activeTab === 'month') {
                this.showDateTime = this.curCheckTime[0] + this.$i18n.t("component.chatter.label.to") + this.curCheckTime[1];
                this.$emit('checkTimePicker', "month", this.curCheckTime);
            } 
            return
        }
        // 默认tab切换值时匹配对应页面显示的值showDateTime及日期插件选中的值
        this.time = this.$moment(this.checkDateTime);
        if (this.activeTab === 'date') {
            this.showDateTime = this.$moment(this.checkDateTime).format("YYYY-MM-DD");
            this.$emit('checkTimePicker', "date", this.showDateTime,)
        } else if (this.activeTab === 'week') {
            let start = this.$moment(this.checkDateTime).startOf('week').format("YYYY-MM-DD");
            let end = this.$moment(this.checkDateTime).endOf('week').format("YYYY-MM-DD");
            this.showDateTime = start +" "+ this.$i18n.t("component.chatter.label.to")+" " + end;
             this.$emit('checkTimePicker', "week", [start,end],);
        } else if (this.activeTab === 'month') {
            let start = this.$moment(this.checkDateTime).startOf('months').format("YYYY-MM-DD");
            let end = this.$moment(this.checkDateTime).endOf('months').format("YYYY-MM-DD");
            this.showDateTime = start +" "+ this.$i18n.t("component.chatter.label.to")+" " + end;
            this.$emit('checkTimePicker', "month", [start,end]);
        } 
    },
    mounted () {
    //    if(this.$cookies.get("activeTab")&&this.$cookies.get("showDateTime")){
    //        this.showDateTime=this.$cookies.get("showDateTime")
    //        this.activeTab=this.$cookies.get("activeTab")
    //        let datatype=this.$cookies.get("activeTab")
    //        let datechare=this.$cookies.get("showDateTime")
    //        let dataarr=datechare.split('到')
    //        if(datatype=='week'){
    //            this.$emit('checkTimePicker', "week", [dataarr[0],dataarr[1]],);
    //            var myDate = new Date(dataarr[0]);
    //             myDate.setDate(myDate.getDate() + 1);
    //         this.time=myDate
    //        }else if(datatype=='date'){
    //            this.$emit('checkTimePicker', "date", this.showDateTime);
    //            this.time=new Date(this.showDateTime)
    //        }else if(datatype=='month'){
    //             this.$emit('checkTimePicker', "month", [dataarr[0],dataarr[1]],);  
    //             this.time=new Date(dataarr[0]);
    //        }
    //    }
    }
}
</script>
<style lang='scss'>
.projrctAllTinmeOut {
    padding: 0;
    width: 345px!important;
}
.projrctAllTinme {
    margin: -28px -0 0 0 !important;
    border: 0 !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
    transition: none;
    opacity: 1;
    height: 330px;
}
.projrctAllTinme.el-popper .popper__arrow,
.projrctAllTinme.el-popper .popper__arrow::after {
    display: none !important;
}
.projrctAllTinme.el-popper[x-placement^="bottom"] .popper__arrow {
    display: none !important;
}
.projrctAllTinme.el-popper[x-placement^="bottom"] .popper__arrow::after {
    display: none !important;
}
</style>
<style lang='scss' scoped>
.allTime {
    display: flex;
}
.checkIcon {
    cursor: pointer;
    display: inline-block;
    width: 20px;
    text-align: center;
}
.dateIcon {
    margin-right: 5px;
}
::v-deep .el-date-editor {
    opacity: 0;
    margin-top: -50px;
}
::v-deep .el-tabs__content {
    height: 330px;
    overflow: hidden;
}
::v-deep .el-tabs__active-bar {
    display: none;
}
::v-deep .el-tabs__nav-scroll {
    display: flex;
}
::v-deep .el-tabs__item {
    width: 25%;
    text-align: center;
    &.is-active {
        border-bottom: 2px solid #409eff;
    }
}
::v-deep .el-tabs__header {
    width: 100%;
    padding: 0;
    .el-tabs__nav {
        width: 100%;
    }
}
::v-deep .tabContainer {
    transition: none;
}
</style>
