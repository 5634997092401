<template>
  <div class="contentall" style="padding: 10px 0; height: 100%">
    <div class="maincontent">
      <!-- 顶部信息 -->
      <div class="inputDeep">
        <div v-show="listcheckout == 0">
          <!-- 列表视图--日期0用户1 -->
          <el-select
            v-model="value"
            v-if="listoption == '0'"
            @change="change"
            class="select_div"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- 网格视图--项目0用户1切换 -->
          <el-select
            v-model="values"
            v-if="listoption != '0'"
            @change="getGridView('select')"
            class="select_div"
          >
            <el-option
              v-for="item in options2"
              :key="item.values"
              :label="$t(item.label)"
              :value="item.values"
            ></el-option>
          </el-select>
          <div
            class="inputMember_dev"
            v-if="listoption == '0' || (listoption != '0' && values != '0')"
          >
            <el-input
              v-model="inputMember"
              :placeholder="$t('label.projectManagement.Pleaseenteramember')"
              prefix-icon="el-icon-search"
              class="memberSearch"
              @keyup.enter.native="memberSearch()"
            ></el-input>
          </div>
        </div>
        <div class="chooseid" v-show="listcheckout > 0">
          <span
            >{{
              $t("vue_label_commonobjects_view_selected", {
                checkedLength: listcheckout,
              })
            }}
            <i class="el-icon-close" @click="channlcheck"></i
          ></span>
          <el-button size="small" @click="alldelete">{{
            $t("label.batchDelete")
          }}</el-button>
        </div>
        <div role="1" class="AlltimeClass">
          <Alltime
            @checkTimePicker="checkTimePicker"
            :activeTabNow="activeTabNow"
            :curCheckTime="[$route.query.startDate, $route.query.endDate]"
          />
        </div>
        <!--视图切换--列表0网格1  -->
        <div>
          <el-select
            v-model="listoption"
            @change="changelist"
            style="margin-right: 20px"
            class="select_div select_div1"
          >
            <el-option
              v-for="item in listoptions"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- 新建 -->
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 10px"
            @click="add"
            >{{ $t("label.import.page1.newcreate") }}</el-button
          >
          <!-- 批量新增 -->
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 20px"
            @click="handleClick()"
            >{{ $t("label.batchadd") }}</el-button
          >
          <!-- 批量新增 -->
          <!-- <el-button  type="primary" plain size="mini" style="margin-right: 20px"  @click="handleClick(btn.name)">批量新增</el-button> -->
        </div>
      </div>
      <!-- 列表视图 -->
      <Listviews
        ref="Listviews"
        :tableData="tableData"
        v-if="listoption == '0'"
        :loading="loading"
        :valuestate="value"
        :billingdata="billingdata"
        :nobillingdata="nobillingdata"
        :alldata="alldata"
        :startDate="startDate"
        :endDate="endDate"
        :activeTabNow="activeTabNow"
        @change="change"
        @allchoose="allchoose"
        @deletebatch="deletebatch"
      ></Listviews>
      <!-- 网格视图 -->
      <Gridview
        :startDate="startDate"
        :endDate="endDate"
        v-else
        :activeTabNow="activeTabNow"
        :tableData="tableData"
        :loading="loading"
        @loadmore="loadmore"
      ></Gridview>
      <!-- 底部信息 -->
      <div class="bottom_infos" v-if="listoption == '0'">
        <div>
          <!-- 计费 -->
          <span v-if="billingdata">{{
            $t("label.projectManagement.charging")
          }}</span
          ><span style="color: #f7847f; margin-left: 10px" v-if="billingdata"
            >{{ billingdata }}h</span
          >
          <!-- 不计费 -->
          <span style="margin-left: 10px" v-if="nobillingdata">{{
            $t("label.projectManagement.nocharge")
          }}</span
          ><span style="color: #ffcb7a; margin-left: 10px" v-if="nobillingdata"
            >{{ nobillingdata }}h</span
          >
          <!-- 全部 -->
          <span style="margin-left: 10px" v-if="alldata">{{
            $t("label.emailobject.view.all")
          }}</span
          ><span style="color: #69a9f4; margin-left: 10px" v-if="alldata"
            >{{ alldata }}h</span
          >
        </div>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="listcurrentPage"
            :page-sizes="[10, 25, 50, 100, 200]"
            :page-size="listpagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="listpagetotal"
          >
          </el-pagination>
        </div>
      </div>
      <div class="bottom_infos" v-else>
        <div>
          <!-- 计费 -->
          <span v-if="billingdata">{{
            $t("label.projectManagement.charging")
          }}</span
          ><span style="color: #f7847f; margin-left: 10px" v-if="billingdata"
            >{{ billingdata }}h</span
          >
          <!-- 不计费 -->
          <span style="margin-left: 10px" v-if="nobillingdata">{{
            $t("label.projectManagement.nocharge")
          }}</span
          ><span style="color: #ffcb7a; margin-left: 10px" v-if="nobillingdata"
            >{{ nobillingdata }}h</span
          >
          <!-- 全部 -->
          <span style="margin-left: 10px" v-if="alldata">{{
            $t("label.category.1")
          }}</span
          ><span style="color: #69a9f4; margin-left: 10px" v-if="alldata"
            >{{ alldata }}h</span
          >
        </div>
        <div></div>
      </div>
    </div>
    <!-- 新增/修改记录 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="'p10'"
      :objectApi="'CloudccProjectActualWorkList'"
      :object-name="tabName"
      :type="type"
      :realObjId="'projectworklist'"
      :relativeRecordId="relativeRecordId"
      :relationFieldId="relationFieldId"
      @save="save"
    >
    </create-edit-obj>
  </div>
</template>
<script>
/**
 * 实际工作清单
 */
import Listviews from "./listviews.vue";
import Alltime from "./Alltime.vue";
import Gridview from "./gridview.vue";
import * as actualworklistApi from "./api.js";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import debounce from "lodash.debounce";
export default {
  components: {
    Listviews,
    Alltime,
    Gridview,
    createEditObj,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      type: "active",
      startDate: "",
      endDate: "",
      activeTabNow: this.$route.query.activeTabNow || "week",
      options: [
        {
          value: "0",
          label: "label.projectManagement.groupbydate", //按日期分组
        },
        {
          value: "1",
          label: "label.projectManagement.Groupuser", //按用户分组
        },
      ],
      listoptions: [
        {
          value: "0",
          label: "vue_label_help_list", //列表视图
        },
        {
          value: "1",
          label: "label.projectManagement.Gridview", //网格视图
        },
      ],
      value: "0",
      values: "0",
      listoption: "0",
      tableData: [],
      loading: false,
      billingdata: "",
      nobillingdata: "",
      alldata: "",
      showBtns: [], //操作按钮
      btnOther: [],
      tabName: "",
      relativeRecordId: "",
      relationFieldId: "",
      options2: [
        {
          values: "0",
          label: "c110", //按项目分组
        },
        {
          values: "1",
          label: "label.projectManagement.Groupuser", //按用户分组
        },
      ],
      inputMember: "", //成员模糊搜索
      listcurrentPage: 1, //列表视图的当前页
      listpagetotal: 1, //列表页数据总条数
      listpagesize: 25, //列表页每页数据条数
      listcheckout: 0, //列表视图选择的数据
      listcheckoutval: "", //列表视图选择的数据id
      gridpage: 1, //网格图当前页
      gridpagesize: 50, //网格图每页数据条数
      gridnextpage: false, //网格视图是否有下一页
      gridtotal: 0, //网格视图总条数
    };
  },
  watch: {
    listoption() {
      this.$cookies.set("listoption", this.listoption, { sameSite: 'Strict' });
    },
    value() {
      this.$cookies.set("workvalue", this.value, { sameSite: 'Strict' });
    },
    values() {
      this.$cookies.set("workvalues", this.values, { sameSite: 'Strict' });
    },
  },
  methods: {
    //取消选中
    channlcheck() {
      this.$refs.Listviews.channlcheck();
    },
    //列表视图选择数据
    allchoose(val, cost) {
      this.listcheckout = val;
      this.listcheckoutval = cost;
    },
    alldelete() {
      this.$refs.Listviews.openpopver();
    },
    //批量删除
    deletebatch() {
      let params = {
        ids: this.listcheckoutval,
        prefix: "p10",
      };
      actualworklistApi.batchDelete(params).then((res) => {
        if (res.result) {
          this.listcheckout = 0; //列表视图选择的数据
          this.listcheckoutval = ""; //列表视图选择的数据id
          this.$message.success(this.$i18n.t("label.tabpage.delsuccessz"));
          this.change();
        }
      });
    },
    //列表页改变每页的条数
    handleSizeChange(val) {
      this.listpagesize = val;
      this.change("change");
    },
    //列表页当前页
    handleCurrentChange(val) {
      this.listcurrentPage = val;
      this.change("change");
    },
    //网格视图滚动加载
    loadmore: debounce(function () {
      if (this.gridnextpage) {
        this.getGridView("useradd");
      } else {
        return;
      }
    }, 400),
    // 成员模糊搜索
    memberSearch() {
      if (this.listoption == "1") {
        // 网格
        this.getGridView();
      } else {
        // 列表
        this.change();
      }
    },
    // 获取视图列表按钮权限
    // getViewListRecently() {
    //   CommonObjApi.getViewListRecently({
    //     obj: "p10",
    //     viewId: "aec20214525FAA8JkV7R",
    //   }).then((res) => {
    //     this.tabName = res.data.label
    //     this.showBtns = res.data.listBtn;
    //     this.btnOther = res.data.listBtn.slice(1)
    //   })
    // },
    // 处理除新建外其余按钮点击事件
    handleClick() {
      // if (name == 'Batch Added') {
      // 批量新增
      this.$router.push({
        path: "/actualworklist/batchAdd/CreatProObjChild",
      });
      // }
    },
    // 新建按钮
    add() {
      this.relativeRecordId = this.$route.params ? this.$route.params.id : "";
      this.relationFieldId = "ffe20worklistp1I11";
      this.$refs.createEditObj.add();
    },
    // 新建成功的回调
    save() {
      if (this.value == "1" && this.listoption == "0") {
        this.findUserIdProjectWorkList();
      } else if (this.value == "0" && this.listoption == "0") {
        this.findTimeBucket();
        this.$emit("setRelationList");
      } else if (this.listoption == "1") {
        this.getGridView();
      }
    },
    //获取以用户分组的列表视图
    findUserIdProjectWorkList() {
      this.loading = true;
      this.tableData = [];
      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
        recordId: this.id, //项目id
        principal: this.inputMember, //成员
        cloudccPageSize: this.listpagesize.toString(),
        cloudccPagination: this.listcurrentPage.toString(),
      };
      actualworklistApi.findUserIdProjectWorkList(params).then((res) => {
        // if (res.returnCode == "1") {
        // for (let i in res.data.data) {
        //   res.data.data[i].id = i;
        // }
        for (let i in res.data.data) {
          res.data.data[i].id = i;
          res.data.data[i].choose = "";
          for (let j = 0; j < res.data.data[i].workerList.length; j++) {
            res.data.data[i].workerList[j].parentid = i;
            res.data.data[i].workerList[j].checkout = false;
            res.data.data[i].workerList[j].deletali = "false";
            res.data.data[i].workerList[j].popshow = "false";
          }
        }
        this.listpagetotal = res.data.listSize;
        this.tableData = res.data.data;
        this.loading = false;
        // }
      });
    },
    //获取以日期分组的列表视图
    findTimeBucket() {
      this.loading = true;
      this.tableData = [];
      let params = {
        startDate: this.$route.query.startDate || this.startDate,
        endDate: this.$route.query.endDate || this.endDate,
        recordId: this.id, //项目id
        principal: this.inputMember, //成员
        cloudccPageSize: this.listpagesize.toString(),
        cloudccPagination: this.listcurrentPage.toString(),
      };
      actualworklistApi.findTimeBucket(params).then((res) => {
        // if (res.returnCode == "1") {
        for (let i in res.data.data) {
          res.data.data[i].id = i;
          res.data.data[i].choose = "";
          for (let j = 0; j < res.data.data[i].workerList.length; j++) {
            res.data.data[i].workerList[j].parentid = i;
            res.data.data[i].workerList[j].checkout = false;
            res.data.data[i].workerList[j].deletali = "false";
            res.data.data[i].workerList[j].popshow = "false";
          }
        }
        this.listpagetotal = res.data.listSize;
        this.tableData = res.data.data;
        this.loading = false;
        // }
      });
    },
    //获取展示页面时间段内的时长汇总
    getCurveTimeBucket() {
      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
        recordId: this.id, //项目id
        principal: this.inputMember, //成员
      };
      actualworklistApi.getCurveTimeBucket(params).then((res) => {
        // if (res.returnCode == "1") {
        this.billingdata = res.data.yeshours;
        this.nobillingdata = res.data.nohours;
        this.alldata = res.data.sumhours;
        // }
      });
    },
    //某一天的前几天或者后几天
    getNextDate(date, day) {
      var dd = new Date(date);
      dd.setDate(dd.getDate() + day);
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1;
      var d = dd.getDate();
      return y + "-" + m + "-" + d;
    },
    isBlank(val) {
      if (val == null || val == "") {
        return true;
      }
    },
    //获取实际工作清单网格视图
    getGridView(add) {
      this.loading = true;
      let value = "";
      this.listoption == "0" ? (value = this.value) : (value = this.values);
      if (add && add == "useradd") {
        this.gridpage++;
      } else if (add && add == "select") {
        this.gridpage = 1;
        this.gridnextpage = false;
        this.gridtotal = 0;
        this.tableData = [];
      } else {
        this.tableData = [];
      }
      let params = {
        start_date: this.startDate,
        end_date: this.endDate,
        projectId: this.id, //项目id
        viewType: value == "0" ? "project" : "user",
        principal: value == "1" ? this.inputMember : "",
        cloudccPageSize: this.gridpagesize.toString(), //一页多少条数据
        cloudccPagination: this.gridpage.toString(), //第几页
      };
      actualworklistApi.getGridView(params).then((res) => {
        // if (res.returnCode == "1") {
        if (!add) {
          this.gridtotal = 0;
        } else if (add && add == "select") {
          this.gridtotal = 0;
        }

        let arre = [];
        for (let i in res.data.data) {
          for (let j in res.data.data[i].everydayList) {
            arre.push(Number(res.data.data[i].everydayList[j].tadyWorkHour));
          }
          var arr = [];
          let length = arre.length / res.data.data.length;
          for (var y = 0; y < arre.length; y += length) {
            arr.push(arre.slice(y, y + length));
          }
        }
        if (this.gridpagesize * this.gridpage < res.data.listSize) {
          this.gridnextpage = true;
        } else {
          this.gridnextpage = false;
        }
        let result = [];
        //遍历json
        for (let key in arr) {
          //遍历数组的每一项
          arr[key].forEach((value, index) => {
            if (this.isBlank(result[index])) {
              result[index] = 0;
            }
            result[index] += value;
          });
        }
        let crr = [];
        crr[0] = this.startDate;
        if (this.activeTabNow == "week") {
          for (let i = 1; i < 7; i++) {
            this.weekendday = this.getNextDate(this.startDate, i);
            crr.push(this.weekendday);
          }
        }
        var obj = {};
        obj.workHour = 0;
        // obj.allWorkinHours = this.sum(result);
        obj.everydayList = [];
        if (this.activeTabNow == "month") {
          for (let i = 1; i < result.length; i++) {
            this.weekendday = this.getNextDate(this.startDate, i);
            crr.push(this.weekendday);
          }
        }
        for (let i = 0; i < result.length; i++) {
          obj.workHour += result[i];
          obj.everydayList.push({
            tadyWorkHour: result[i],
            duedate: crr[i],
            all: 2,
          });
        }
        obj.id = "";
        obj.name = "总计";
        obj.objtype = "";
        // obj.theirProjectccname = "";
        obj.all = "1";
        if (res?.data?.data?.length) {
          res.data.data.push(obj);
        }

        if (add && add == "useradd") {
          let oldtotal = this.tableData[this.tableData.length - 1];
          let newtotal = res.data.data[res.data.data.length - 1];
          newtotal.workHour += oldtotal.workHour;
          for (let i = 0; i < newtotal.everydayList.length; i++) {
            newtotal.everydayList[i].tadyWorkHour +=
              oldtotal.everydayList[i].tadyWorkHour;
          }
          // let totalz=res.data.data[res.data.data.length-1];
          this.tableData.splice(this.tableData.length - 1, 1);
          res.data.data.splice(res.data.data.length - 1, 1);

          for (let i = 0; i < this.tableData.length; i++) {
            for (let j = 0; j < res.data.data.length; j++) {
              if (this.tableData[i].id == res.data.data[j].id) {
                if (params.viewType == "user") {
                  for (let x = 0; x < this.tableData[i].childList.length; x++) {
                    for (
                      let y = 0;
                      y < res.data.data[j].childList.length;
                      y++
                    ) {
                      if (
                        this.tableData[i].childList[x].id ==
                        res.data.data[j].childList[y].id
                      ) {
                        this.tableData[i].childList[x].childList =
                          this.tableData[i].childList[x].childList.concat(
                            res.data.data[j].childList[y].childList
                          );
                        this.tableData[i].childList[x].workHour = 0;
                        this.tableData[i].childList[x].everydayList.forEach(
                          (item, index) => {
                            item.tadyWorkHour =
                              eval(item.tadyWorkHour) +
                              eval(
                                res.data.data[j].childList[y].everydayList[
                                  index
                                ].tadyWorkHour
                              );
                            this.tableData[i].childList[x].workHour =
                              eval(item.tadyWorkHour) +
                              eval(this.tableData[i].childList[x].workHour);
                          }
                        );
                        res.data.data[j].childList.splice(y, 1);
                      }
                    }
                  }
                }
                this.tableData[i].workHour = 0;
                this.tableData[i].childList = this.tableData[
                  i
                ].childList.concat(res.data.data[j].childList);
                this.tableData[i].everydayList.forEach((item, index) => {
                  item.tadyWorkHour =
                    eval(item.tadyWorkHour) +
                    eval(res.data.data[j].everydayList[index].tadyWorkHour);
                  this.tableData[i].workHour =
                    eval(item.tadyWorkHour) + eval(this.tableData[i].workHour);
                });
                res.data.data.splice(j, 1);
              }
            }
          }
          this.tableData = this.tableData.concat(res.data.data, newtotal);
          // if(this.tableData[this.tableData.length-2].id==res.data.data[0].id){
          //   this.tableData[this.tableData.length-2].childList=this.tableData[this.tableData.length-2].childList.concat(data.data[0].childList)
          //   res.data.data.splice(0,1)
          //   this.tableData=this.tableData.concat(res.data.data)
          // }else{
          //   this.tableData.splice(this.tableData.length-1,1)
          //   this.tableData=this.tableData.concat(res.data.data)
          // }
          //   if(this.tableData[this.tableDate.length-2].id==res.data.data[0].id){

          //   }else{
          //     this.tableData=this.tableData.splice(this.tableData.length-1,1)
          //     this.tableData=this.tableData.concat(res.data.data)
          //     
          //   }
        } else {
          this.tableData = res.data.data;
        }

        this.billingdata = res.data.allHourBillng;
        (this.nobillingdata = res.data.allHourNoBillng),
          (this.alldata = res.data.allHour);
        // }
        this.getCurveTimeBucket();
        this.loading = false;
      });
    },
    //获取时间选择器中的时间
    checkTimePicker(type, num) {
      this.activeTabNow = type;
      if (type == "date") {
        this.startDate = num;
        this.endDate = num;
      } else {
        this.startDate = num[0];
        this.endDate = num[1];
      }
      if (this.listoption == "1") {
        this.gridpage = 1;
        this.gridnextpage = false;
        this.getGridView();
      }
      if (this.value == "0" && this.listoption == "0") {
        this.$refs.Listviews && this.$refs.Listviews.initcheck();
        this.listcheckout = 0; //列表视图选择的数据
        this.listcheckoutval = ""; //列表视图选择的数据id
        this.findTimeBucket();
        this.getCurveTimeBucket();
      }
      if (this.value == "1" && this.listoption == "0") {
        this.$refs.Listviews && this.$refs.Listviews.initcheck();
        this.listcheckout = 0; //列表视图选择的数据
        this.listcheckoutval = ""; //列表视图选择的数据id
        this.findUserIdProjectWorkList();
      }
    },
    sum(arr) {
      var s = 0;
      arr.forEach(function (val) {
        s += val;
      }, 0);
      return s;
    },

    //日期或者用户选项改变
    change(val) {
      if (!val) {
        this.listcurrentPage = 1;
      }
      // this.$refs.Listviews.initcheck();
      this.listcheckout = 0; //列表视图选择的数据
      this.listcheckoutval = ""; //列表视图选择的数据id
      if (this.value == "1") {
        // 用户
        this.findUserIdProjectWorkList();
        this.getCurveTimeBucket();
      }
      if (this.value == "0") {
        // 项目
        this.findTimeBucket();
        this.getCurveTimeBucket();
      }
    },
    changelist() {
      if (this.listoption == "1") {
        // 网格
        this.listcheckout = 0; //列表视图选择的数据
        this.listcheckoutval = ""; //列表视图选择的数据id
        this.$refs.Listviews.initcheck();
        this.getGridView();
      }
      if (this.listoption == "0") {
        // 列表
        this.change();
      }
    },
  },
  mounted() {
    //设置下拉框宽度
    if (sessionStorage.getItem("cacheSetLang") !== "zh") {
      document.querySelector(".select_div").style.width = "147px";
    }
    this.changelist();
  },
  created() {
    if (this.$cookies.get("listoption")) {
      this.listoption = this.$cookies.get("listoption");
    } else {
      this.listoption = "0";
    }
    if (this.$cookies.get("workvalue")) {
      this.value = this.$cookies.get("workvalue");
    } else {
      this.value = "0";
    }
    if (this.$cookies.get("workvalues")) {
      this.values = this.$cookies.get("workvalues");
    } else {
      this.values = "0";
    }
    // this.findTimeBucket();
    // this.getCurveTimeBucket();
  },
};
</script>
<style lang='scss' scoped>
/* 利用穿透，设置input边框隐藏 */
.inputDeep >>> .el-input__inner {
  border: 0;
}
::v-deep .el-table::before {
  background-color: transparent !important;
}
::v-deep .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent !important;
}
::v-deep .el-table__fixed::before {
  background-color: transparent !important;
}
::v-deep .el-table::before {
  background-color: #ebeef5 !important;
}
::v-deep .el-table .cell {
  white-space: break-spaces;
}
::v-deep .el-table__fixed-left::before,
.el-table__fixed::before {
  background-color: transparent !important;
}
.maincontent {
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .inputDeep {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    .select_div {
      border: 0;
      outline: none;
      cursor: pointer;
      width: 110px;
      &.select_div1 {
        width: 100px;
      }
      ::v-deep .el-input--suffix .el-input__inner {
        border: 0;
        color: #000;
        font-size: 13px;
      }
      ::v-deep .el-input .el-select__caret {
        color: #000;
        font-weight: bold;
      }
    }
    .inputMember_dev {
      margin-left: 12px;
      display: inline-block;
      width: 206px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      // border: 1px solid #e1e1e1;
      .memberSearch ::v-deep .el-input__prefix {
        top: -3px;
      }
      ::v-deep .el-input__inner {
        height: 32px;
      }
    }
  }
  .bottom_infos {
    background: #fff;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }
  .AlltimeClass {
    margin-top: 12px;
  }
}
.chooseid {
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    height: 24px;
    padding: 5px 15px;
    border: 1px solid #dcdfe6;
    border-radius: 24px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 24px;
    i {
      cursor: pointer;
      padding-left: 10px;
    }
  }
  ::v-deep .el-button {
    height: 30px;
  }
}
</style>