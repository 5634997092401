<template>
  <div class="content">
    <el-table
      v-if="tableData"
      :data="tableData"
      v-loading="loading"
      style="width: 100%"
      height="100%"
      :row-class-name="tableRowClassName"
      row-key="id"
      default-expand-all
      :tree-props="{ children: 'workerList', hasChildren: 'hasChildren' }"
      :header-cell-style="{ color: '#000000' }"
      @cell-mouse-enter="cellenter"
      @cell-mouse-leave='cellleave'
    >
      <template slot="empty">
        <!-- 暂无数据 -->
        <span>{{ $t("vue_label_SMS_nodata") }}</span>
      </template>
      <!--  任务/问题-->
      <el-table-column
        prop="pdate"
        :label="$t('label.projectManagement.Taskorproblem')"
        min-width="200"
      >
        <template scope="scope">
          <span v-if="!scope.row.member">
            <!-- <input type="checkbox" class="checks"> -->
            <svg aria-hidden="true" class="checks"  v-if="scope.row.choose&&scope.row.choose!==''" @click="selectchoose(scope.row.choose,scope.row.id)">
                  <use :href="scope.row.choose=='true'?'#icon-Click-Check':'#icon-a-selectall'"></use>
            </svg>
            <span>{{ scope.row.pdate }}</span
            ><span
              style="margin-left: 20px; color: #f6817b"
              v-if="scope.row.yesHours"
              >{{ scope.row.yesHours }}h</span
            ><span
              style="margin-left: 20px; color: #fbcc84"
              v-if="scope.row.noHours"
              >{{ scope.row.noHours }}h</span
            ><span
              style="margin-left: 20px; color: #6dacf3"
              v-if="scope.row.sumHours"
              >{{ scope.row.sumHours }}h</span
            ></span
          >
          <span
            v-else
            style="color: #006dcc; cursor: pointer;position: relative;"
            >
            <!-- <span  @click="popshow=true"  class="caozuo">...</span>
            <ul class="actionul" v-if="popshow">
              <li v-for="(item,index) in options" :key='index'>{{item.label}}</li>
            </ul> -->
            <el-popover
              placement="bottom-start"
              width="80"
              trigger="click"
              v-if="scope.row.deletali=='true'"
              @hide='pophide(scope.row)'
              >
              <p  class="actionul" v-for="(item,index) in options" :key='index'  @click="deletone(scope.row)">{{item.label}}</p>
              <span class="caozuo" slot="reference"  @click="popsh(scope.row)">...</span>
              </el-popover>
            <input type="checkbox" class="chenckbox" v-model="scope.row.checkout" @click="dancheckout(scope.row)">
           <span @click="detial(scope.row)" >{{ scope.row.task_or_problem }}</span> </span
          >
        </template>
      </el-table-column>
      <!-- 项目 -->
      <el-table-column
        prop="projectName"
        :label="$t('label.file.item')"
        show-overflow-tooltip  
      >
      <template scope="scope">
          <span
            @click="currencydetial(scope.row)"
            style="color: #006dcc; cursor: pointer"
            >{{ scope.row.projectName }}</span
          >
        </template>
      </el-table-column>
      <!-- 成员 -->
      <el-table-column
        prop="member"
        min-width="60"
        :label="$t('label.marketseamemset.user')"
        v-if="valuestate == '0'"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.member }}</p>
        </template>
      </el-table-column>
      <!-- 日期 -->
      <el-table-column
        prop="pdate"
        show-overflow-tooltip
        :label="$t('date')"
        v-if="valuestate == '1'"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.project_name">{{ scope.row.pdate }}</p>
        </template>
      </el-table-column>
      <!-- 备注 -->
      <el-table-column
        prop="remarks"
        :label="$t('label.weixin.group.description')"
        width="300"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- 计费 -->
      <el-table-column
        prop="settlementType"
        :label="$t('label.projectManagement.charging')"
        width="80"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- 报工工时（h） -->
      <el-table-column
        prop="actual_working_hours"
        show-overflow-tooltip
        :label="$t('front-project-module-v1-actual_working_hours')"
      >
      </el-table-column>
      <!-- 创建人 -->
      <el-table-column
        prop="createbyname"
        :label="$t('label.createby')"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table>
    <!-- 删除框 -->
    <el-dialog
      :title="$t('label.popup.infoz')"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <span>{{$t('c519')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('label.tabpage.cancel')}}</el-button>
        <el-button type="primary" @click.native="deleteworks">{{$t('label.tabpage.ok')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as actualworklistApi from "./api.js";
/*
 * 列表视图
 */
export default {
  props: {
    tableData: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "0",
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    valuestate: {
      type: String,
      default: "",
    },
    // 详情页返回需要的参数
    showDateTimes: {
      type: String,
      default: "",
    },
    activeTabNow: {
      type: String,
      default: "week",
    },
  },
  data() {
    return {
      buytypeoptions: [
        {
          value: this.$i18n.t("label.projectManagement.charging"), //计费
          label: this.$i18n.t("label.projectManagement.charging"), //计费,
        },
        {
          value: this.$i18n.t("label.projectManagement.nocharge"), //不计费
          label: this.$i18n.t("label.projectManagement.nocharge"), //不计费
        },
      ],
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      tableHeight: null,
      options:[{
        label:this.$i18n.t('component.chatter.button.delete'),
        value:1,
      }],
      popshow:false,
      dialogVisible:false,//删除提示框
      deleteid:'',//单条删除数据的id
      choose:{},//分组统计子节点选中状态
      deleteall:'',//批量删除的id
    };
  },
  methods: {
    //重置选中数据
    initcheck(){
      this.choose={};//分组统计子节点选中状态
      this.deleteall='';//批量删除的id
    },
    //全部取消
    channlcheck(){
      this.choose={}
      this.deleteall=''
      this.tableData.forEach((res)=>{
        res.choose=''
        res.workerList.forEach((item)=>{
          item.checkout=false
          item.deletali='false';
          item.popshow='false';
        })
      })
    },
    //全选 反选
    selectchoose(val,index){
      if(val=='false'){
         this.$set(this.tableData[index],'choose','true')
         this.tableData[index].workerList.forEach((res)=>{
           if(!res.checkout){
             res.checkout=true
             this.choose[index].push(res.id)
             if(this.deleteall==''){
                this.deleteall=res.id
              }else{
                this.deleteall+=","+res.id
              }
           }
           
         })
      }else if(val=='true'){
        this.$set(this.tableData[index],'choose',"")
         let arr=this.deleteall.split(',')
         this.choose[index]=[]
        this.tableData[index].workerList.forEach((res)=>{
          if(res.checkout){
           res.checkout=false
            let num=arr.indexOf(res.id)
            if(num!==-1){
              arr.splice(num,1)
            }
           
          }
        })
       this.deleteall=arr.join(',')
      }
    },
    //行选择
    dancheckout(row){
      if(!row.checkout){
        if(this.choose[row.parentid]){
          this.choose[row.parentid].push(row.id)
        }else{
          this.choose[row.parentid]=[]
          this.choose[row.parentid].push(row.id)
        }
        if(this.deleteall==''){
          this.deleteall=row.id
        }else{
          this.deleteall+=","+row.id
        }
        if(this.tableData[row.parentid].workerList.length==this.choose[row.parentid].length){
          this.$set(this.tableData[row.parentid],'choose','true')
        }else if(this.tableData[row.parentid].workerList.length>this.choose[row.parentid].length&&this.choose[row.parentid].length>0){
          this.$set(this.tableData[row.parentid],'choose','false')
        }
      }else{
        if(!this.choose[row.parentid]){
          this.choose[row.parentid]=[]
        }
        this.choose[row.parentid].forEach((res,index)=>{
          if(res==row.id){
            this.choose[row.parentid].splice(index,1)
          }
        })
        let deleteidarr=this.deleteall.split(',')
        deleteidarr.forEach((res,index)=>{
          if(res==row.id){
            deleteidarr.splice(index,1)
          }
        })
        this.deleteall=deleteidarr.join(',')
        if(this.choose[row.parentid].length==0){
           this.$set(this.tableData[row.parentid],'choose','')
        }else if(this.tableData[row.parentid].workerList.length>this.choose[row.parentid].length&&this.choose[row.parentid].length>0){
           this.$set(this.tableData[row.parentid],'choose','false')
        }
      } 
    },
    //权限
    getPermissionById(row){
      let params={
        id:row.id,
        isProject:'true'
      }
      actualworklistApi.getPermissionById(params).then((res)=>{
        if(res.result){
          if(res.data.isDelete){
            this.dialogVisible=true
          }else{
            this.$message.warning(this.$i18n.t('front-kefu-moudle-v1-Operation-without0-permission'))
          }
        }
      })
    },
    //操作项-删除
    deletone(row){
      this.deleteid=row.id
      row.deletali='false';
      row.popshow='false';
      this.getPermissionById(row)
    },
    pophide(row){
      row.deletali='false';
      row.popshow='false';
    },
    openpopver(){
      this.dialogVisible=true;
    },
     //删除单个清单
    deletedan(){
      let params={
        id:this.deleteid,
        objectApi: "CloudccProjectActualWorkList",
      }
      actualworklistApi.odelete(params).then((res)=>{
        if(res.result){
          this.dialogVisible=false
          this.deleteid=''
          this.$message.success(this.$i18n.t('label.tabpage.delsuccessz'))
          this.$emit('change')
        }
      })
    },
    //删除框确定
    deleteworks(){
      if(this.deleteall&&this.deleteall !==''){
        this.dialogVisible=false;
        this.$emit('deletebatch')
      }else{
        this.deletedan()
      }
    },
    //行滑入
    cellenter(row){
      if(row.deletali=="false"){
        this.$nextTick(()=>{
          row.deletali='true'
        }) 
      }
    },
    //行滑出
    cellleave(row){
      if(row.deletali=="true"&&row.popshow=='false'){
        this.$nextTick(()=>{
          row.deletali='false'
        })  
      }else{
        row.popshow='false'
      }
    },
    //点击。。。
    popsh(row){
      row.popshow='true'
      // this.aa=false
    },
    //进入详情页
    detial(row) {
      this.$router.push({
        path: `/workListDetails`,
        query: {
          row:JSON.stringify(row),
          state: 1,
          startDate: this.startDate,
          endDate: this.endDate,
          activeTabNow: this.activeTabNow
        },
      });
    },
    currencydetial(row){
      this.$router.push({
            path: `/commonObjects/detail/${row.projectId}/DETAIL`,
            query:{
            ischeck:1,
            startDate:this.startDate,
            endDate:this.endDate,
            activeTabNow:this.activeTabNow
          }
          });
    },
    tableRowClassName({ row }) {
      if (!row.member) {
        return "warning-row";
      }
      return "";
    },
    focusEvent( ) {
      // if(type){
      // row.oldName = row.name
      // // this.nameoptions[0].value = row.name
      // }else if(type==2){
      // // this.addressoptions[0].value = row.address
      // }
    },
    blurEvent(row, type) {
      if (type === 1) {
        row.isSelected = !row.isSelected;
      } else if (type === 2) {
        row.isaddress = !row.isaddress;
      } else if (type === 3) {
        row.isremark = !row.isremark;
      } else if (type === 4) {
        row.isbuytype = !row.isbuytype;
      } else if (type === 5) {
        row.isactualworkinghours = !row.isactualworkinghours;
      }
      // if (row.name !== row.oldName) {
      //   // 。。。此处代码省略（调用修改名称接口）
      //   this.$message({
      //     message: '修改成功',
      //     type: 'success',
      //     duration: 1000
      //   })
      // }
    },
    changeEvent() {},
    cellClick(row, type) {
      if (type === 1) {
        row.isSelected = !row.isSelected;
      } else if (type === 2) {
        row.isaddress = !row.isaddress;
      } else if (type === 3) {
        row.isremark = !row.isremark;
      } else if (type === 4) {
        row.isbuytype = !row.isbuytype;
      } else if (type === 5) {
        row.isactualworkinghours = !row.isactualworkinghours;
      }
    },
    resizeCallback(offsetHeight){
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    }
  },
  watch: {
    tableData: {
      immediate: true,
      handler() {
        // this.tableData = val;
        // this.gettime(val);
      },
    },
    deleteall(){
      let allids=this.deleteall.split(',');
      if(allids[0]==''&&allids.length==1){
        this.$emit('allchoose',0)
      }else{
        this.$emit('allchoose',allids.length,this.deleteall)
      }
    },
  },
  mounted() {
    this.tableHeight =
      document.getElementsByClassName("home_content")[0].clientHeight - 217;
    this.$bus.$on("windowResize", this.resizeCallback);
  },
  beforeDestroy() {
    this.$bus.$off("windowResize", this.resizeCallback);
  },
  // watch: {
  //   valuestate(nval) {},
  // },
};
</script>
<style lang='scss' scoped>
.content {
  height: calc(100% - 100px);
}
.el-table .warning-row {
  background: #fafaf9;
}

.el-table .success-row {
  background: #f0f9eb;
}
::v-deep .el-table th.el-table__cell>.cell{
  padding-left:40px;
}
/*去除表头全选框*/
.el-table >>> .disabledSelection .cell .el-checkbox__inner {
  display: none;
  position: relative;
 
}
::v-deep .el-table .cell{
  padding-left: 40px;
}
.chenckbox{
  position: absolute;
  left: -34px;
  top: 5px;
}
.caozuo{
  display: inline-block;
  position: absolute;
  left: -63px;
  top: -7px;
  font-size:22px;
  color:#333;
  // opacity: 1;
   &:hover{
    color:rgb(0, 109, 204);
  }
}
::v-deep .el-table tbody tr:hover{
  .caozuo{
    // opacity: 1;
  }
}
.actionul{
  // position:absolute;
  // top: 20px;
  // left: -63px;
  width: 100%;
  // margin-top:5px;
  // margin-bottom: 5px;
  
    color: #3e3e3c;
    height: 30px;
    line-height:30px;
    // margin-bottom: 5px;
    padding-left:5px;
    font-size: 12px;
    cursor: pointer;
    margin-left:0px;
    margin-right:0px;
  &:hover{
    background: #f3f2f2;
    color: #006dcc;
  }
}
.checks{
  height: 15px;
  width: 15px;
  position: absolute;
  left: 42px;
  top: 15px;
  background: #fff;
  cursor: pointer;
}
</style>
