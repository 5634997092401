/* 
  网格视图
*/
<template>
  <div class="gridcontent">
    <el-table
      ref="multipleTable"
      v-if="tableData"
      :data="tableData"
      v-loading="loading"
      border
      style="width: 100%"
      :header-cell-style="{
        color: '#000000',
        'text-align': 'center',
        background: '#FAFAF9',
      }"
      row-key="id"
      default-expand-all
      :tree-props="{ children: 'childList', hasChildren: 'hasChildren' }"
      :row-class-name="tableRowClassName"
      height="100%"
      v-loadmore="loadMore"
    >
      <!-- 任务/问题 -->
      <el-table-column
        prop="name"
        :label="$t('label.projectManagement.Taskorproblem')"
        width="600"
        fixed
      >
        <template slot-scope="scope">
          <span v-if="scope.row.objtype == 'project'">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-xiangmu"></use>
            </svg>
            {{ scope.row.name }}
          </span>
          <span
            v-else
            @click="detialTask(scope.row)"
            style="color: #006dcc; cursor: pointer"
            >{{ scope.row.name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        v-for="(items, index) in timerange"
        :key="index"
        :label="items"
        prop="name"
        align="center"
        :render-header="renderHeader"
      >
        <template scope="scope">
          <span
            style="color: #006dcc; cursor: pointer"
            v-if="
              scope.row.everydayList[index] &&
              scope.row.everydayList[index].tadyWorkHour > 0 &&
              scope.row.everydayList[index].all == 2
            "
            @click="detail(scope.row, 1, scope.row.everydayList[index])"
            >{{ scope.row.everydayList[index].tadyWorkHour }}h</span
          >
          <span
            v-if="
              scope.row.everydayList[index] &&
              scope.row.everydayList[index].tadyWorkHour > 0 &&
              !scope.row.everydayList[index].all
            "
            >{{ scope.row.everydayList[index].tadyWorkHour }}h</span
          >
        </template>
      </el-table-column>
      <!-- 全部 -->
      <el-table-column
        align="center"
        :label="$t('label.category.1')"
        fixed="right"
        prop="workHour"
      >
        <template scope="scope">
          <span
            v-if="scope.row.all && scope.row.all > 0"
            style="color: #006dcc; cursor: pointer"
            @click="detail(scope.row, 2, scope.row)"
            >{{ scope.row.workHour }}h</span
          >
          <span v-else>{{ scope.row.workHour }}h</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
 <script>
export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 10;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    activeTabNow: {
      type: String,
      default: "week",
    },
  },
  data() {
    return {
      tableHeight: null, // 表格高度
      nowday: "",
      weekendday: "",
      timerange: [],
    };
  },
  methods: {
    // 滚动事件
    loadMore() {
      let that = this;
      // let dom = document.querySelector('.el-table__body-wrapper')
      let dom = document.getElementsByClassName("el-table__body-wrapper")[
        document.getElementsByClassName("el-table__body-wrapper").length - 1
      ];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 0.5) {
          //等于0证明已经到底，可以请求接口
          that.$emit("loadmore");
        }
      });
    },
    /**
     * 进入任务/问题详情页
     */
    detialTask(row) {
      this.$router.push(`/commonObjects/detail/${row.id}/DETAIL`);
    },

    //进入详情页
    detail(row, sign, index = {}) {
      if (row.allWorkinHours == 0) {
        return;
      }
      let startdate = "";
      let enddate = "";
      if (sign == 2) {
        startdate = this.startDate;
        enddate = this.endDate;
      } else {
        startdate = index.duedate;
        enddate = index.duedate;
      }
      this.$router.push({
        path: `/workListDetails`,
        query: {
          state: 2,
          startDate: startdate,
          endDate: enddate,
          activeTabNow: this.activeTabNow,
        },
      });
    },
    //时间表头分行
    renderHeader(h, { column }) {
      let header = column.label.split(" ");
      return [h("p", [h("p", {}, header[0]), h("span", {}, header[1])])];
    },
    //表格第一行和最后一行加背景颜色
    tableRowClassName({ row }) {
      if (row.all) {
        return "warning-row";
      }
      return "";
    },
    //某一天的前几天或者后几天
    getNextDate(date, day) {
      var dd = new Date(date);
      dd.setDate(dd.getDate() + day);
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    //判断周几
    getDateWeek(year, month, day) {
      var tmpdate = new Date(year, month - 1, day);
      return tmpdate.getDay();
    },
    //表头时间数据处理
    gettime(startDate) {
      this.timerange = [];
      let arr = [];
      let brr = [];
      if (this.activeTabNow == "date") {
        arr[0] = this.getDateWeek(
          startDate.substring(0, 4),
          startDate.substring(5, 7),
          startDate.substring(8, 10)
        );
        brr[0] = startDate.substring(8, 10);
      }
      if (this.activeTabNow == "week") {
        arr[0] = this.$i18n.t(
          "label.reportsSubscriptionPage.schedulereport.monthly.day.sunday"
        ); //星期日
        brr[0] = startDate.substring(8, 10);
        for (let i = 1; i < 7; i++) {
          this.weekendday = this.getNextDate(startDate, i);
          arr.push(
            this.getDateWeek(
              this.weekendday.substring(0, 4),
              this.weekendday.substring(5, 7),
              this.weekendday.substring(8, 10)
            )
          );
          brr.push(this.weekendday.substring(this.weekendday.length - 2));
        }
      } else if (this.activeTabNow == "month") {
        arr[0] = this.getDateWeek(
          startDate.substring(0, 4),
          startDate.substring(5, 7),
          startDate.substring(8, 10)
        );
        brr[0] = startDate.substring(8, 10);
        for (let i = 1; i < this.endDate.substring(8, 10); i++) {
          this.weekendday = this.getNextDate(startDate, i);
          arr.push(
            this.getDateWeek(
              this.weekendday.substring(0, 4),
              this.weekendday.substring(5, 7),
              this.weekendday.substring(8, 10)
            )
          );
          brr.push(this.weekendday.substring(this.weekendday.length - 2));
        }
      }
      for (let i in arr) {
        if (arr[i] == "0") {
          arr[i] = this.$i18n.t(
            "label.reportsSubscriptionPage.schedulereport.monthly.day.sunday"
          ); //星期日
        } else if (arr[i] == "1") {
          arr[i] = this.$i18n.t(
            "label.reportsSubscriptionPage.schedulereport.monthly.day.monday"
          ); //"星期一";
        } else if (arr[i] == "2") {
          arr[i] = this.$i18n.t(
            "label.reportsSubscriptionPage.schedulereport.monthly.day.tuesday"
          ); //"星期二";
        } else if (arr[i] == "3") {
          arr[i] = this.$i18n.t(
            "label.reportsSubscriptionPage.schedulereport.monthly.day.wednesday"
          ); //"星期三";
        } else if (arr[i] == "4") {
          arr[i] = this.$i18n.t(
            "label.reportsSubscriptionPage.schedulereport.monthly.day.thursday"
          ); //"星期四";
        } else if (arr[i] == "5") {
          arr[i] = this.$i18n.t(
            "label.reportsSubscriptionPage.schedulereport.monthly.day.friday"
          ); //"星期五";
        } else if (arr[i] == "6") {
          arr[i] = this.$i18n.t(
            "label.reportsSubscriptionPage.schedulereport.monthly.day.saturday"
          ); //"星期六";
        }
      }
      for (let i in arr) {
        this.timerange[i] = brr[i] + " " + arr[i];
      }
    },
  },
  watch: {
    tableData: {
      immediate: true,
      handler() {
        // this.tableData = val;
        this.$nextTick(() => {
          this.$refs.multipleTable.doLayout();
        });
      },
    },
    startDate: {
      immediate: true,
      handler(val) {
        this.gettime(val);
      },
      deep: true,
    },
    endDate: {
      immediate: true,
      handler() {
        this.gettime(this.startDate);
      },
      deep: true,
    },
    activeTabNow: {
      immediate: true,
      handler() {},
      deep: true,
    },
  },

  mounted() {
    this.tableHeight =
      document.getElementsByClassName("home_content")[0].clientHeight - 217;
  },
};
</script>
<style lang='scss' scoped>
.el-table .warning-row {
  background: #fafaf9;
}
::v-deep .el-table {
  th:last-child {
    border-left: 1px solid #eee;
  }
  tr,
  td:last-child {
    border-left: 1px solid #eee;
  }
}
/* 设置了固定列导致的滚动条无法拖动问题 */
/* 左固定列 */
.el-table .el-table__fixed {
  height: auto !important;
  bottom: 30px;
}
/* 右固定列 */
.el-table .el-table__fixed-right {
  height: auto !important;
  bottom: 30px;
}
.gridcontent {
  height: calc(100% - 100px);
  ::v-deep p {
    margin-bottom: 0px;
  }
}
</style>